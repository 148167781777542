@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
  background-color: #121212;
  color: #fff;
}

.flexCenter {
    @apply flex justify-center items-center;
}

.boxWidth {
    @apply xl:max-w-[1280px] w-full;
}

.paddingX {
    @apply lg:px-32 px-6;
}

.paddingY {
    @apply sm:py-20 py-6;
}

.padding {
    @apply sm:px-32 px-6 sm:py-20 py-4;
}

.marginX {
    @apply sm:mx-16 mx-6;
}

.marginY {
    @apply sm:my-16 my-6;
}

.hoverLinks{
    @apply text-[#727272] hover:text-white transition ease-in duration-75
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @apply bg-primary text-white;
}

.flexCenter {
  @apply flex justify-center items-center;
}

.flexBetween {
  @apply flex justify-between items-center;
}

.flexStart {
  @apply flex items-center justify-start;
}

.text-small {
  @apply text-sm font-medium;
}

.paddings {
  @apply lg:px-20 py-6 px-5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

.modal-head-text {
  @apply md:text-5xl text-3xl font-extrabold text-left max-w-5xl w-full;
}

.no-result-text {
  @apply w-full text-center my-10 px-2;
}

/* Project Details */
.user-actions_section {
  @apply fixed max-md:hidden flex gap-4 flex-col right-10 top-20;
}

.user-info {
  @apply flex flex-wrap whitespace-nowrap text-sm font-normal gap-2 w-full;
}

/* Home */
.projects-grid {
  @apply grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mt-10 w-full;
}

/* Project Actions */
.edit-action_btn {
  @apply p-3 text-gray-100 bg-light-blue
 rounded-lg text-sm font-medium;
}

.delete-action_btn {
  @apply p-3 text-gray-100 hover:bg-red-600 rounded-lg text-sm font-medium;
}

/* Related Project Card */
.related_project-card {
  @apply flex-col rounded-2xl min-w-[210px] min-h-[197px];
}

.related_project-card_title {
  @apply justify-end items-end w-full h-1/3 bg-gradient-to-b from-transparent to-black/50 rounded-b-2xl gap-2 absolute bottom-0 right-0 font-semibold text-lg text-white p-4;
}

.related_projects-grid {
  @apply grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 mt-5;
}

/* Custom Menu */
.custom_menu-btn {
  @apply gap-4 w-full rounded-md bg-light-blue
 p-4 text-base outline-none capitalize;
}

.custom_menu-items {
  @apply flex-col absolute left-0 mt-2 xs:min-w-[300px] w-fit max-h-64 origin-top-right rounded-xl bg-white border shadow-menu overflow-y-auto;
}

.custom_menu-item {
  @apply text-left w-full px-5 py-2 text-sm hover:bg-light-blue self-start whitespace-nowrap capitalize;
}

/* Footer */
.footer {
  @apply flex-col paddings w-full gap-20 bg-light-blue
;
}

.footer_copyright {
  @apply max-sm:flex-col w-full text-sm font-normal;
}

.footer_column {
  @apply flex-1 flex flex-col gap-3 text-sm min-w-max;
}

/* Form Field */
.form_field-input {
  @apply w-full outline-0 bg-dark-grey
 rounded-xl p-4;
}

/* Modal */
.modal {
  @apply fixed z-10 left-0 right-0 top-0 bottom-0 mx-auto bg-black/80;
}

.modal_wrapper {
  @apply flex justify-start items-center flex-col absolute h-[95%] w-full bottom-0 bg-primary rounded-t-3xl lg:px-40 px-8 pt-14 pb-72 overflow-auto;
}

/* Navbar */
.navbar {
  @apply py-5 px-8  gap-4;
}

/* Profile Menu */
.profile_menu-items {
  @apply flex-col  absolute right-1/2 translate-x-1/2 mt-3 p-7 sm:min-w-[300px] min-w-max rounded-xl bg-dark-grey  shadow-menu;
}

/* Profile Card */
.profile_card-title {
  @apply justify-end items-end w-full h-1/3 bg-gradient-to-b from-transparent to-black/50 rounded-b-2xl gap-2 absolute bottom-0 right-0 font-semibold text-lg text-white p-4;
}

/* Project Form */
.form {
  @apply flex-col w-full lg:pt-24 pt-12 gap-10 text-lg max-w-5xl mx-auto;
}

.form_image-container {
  @apply w-full lg:min-h-[400px] min-h-[200px] relative;
}

.form_image-label {
  @apply z-10 text-center w-full h-full p-20 text-gray-100 border-2 border-gray-50 border-dashed;
}

.form_image-input {
  @apply absolute z-30 w-full opacity-0 h-full cursor-pointer;
}

/* Profile Projects */
.profile_projects {
  @apply grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 mt-5;
}